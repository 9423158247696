import React from "react";
import { ContainerColored } from "../../global";
import { FC } from "react";
import image from "../../../images/getStarted/artboard_4.svg";
import { GridItem, GridContainer, WrapperColored } from "./style";
import { Typography } from "@material-ui/core";

const MakeAMap: FC = () => {
  return (
    <WrapperColored id="getStarted">
      <ContainerColored>
        <GridContainer>
          <GridItem>
            <img src={image} alt="" />
          </GridItem>
          <GridItem>
            <Typography variant="h4" style={{ fontWeight: 400 }}>
              Make A Basic Map
            </Typography>
            <Typography variant="body1">Ako Maps are made up of ‘nodes’ and ‘connectors’.</Typography>
            <Typography variant="body1">
              A node is a box about an idea or topic – it can also contain content, become a flashcard, or link to a
              whole other map. You can make these on your maps by double - clicking the background.{" "}
            </Typography>
            <Typography variant="body1">
              A connector is an arrow, with some extra features to support your learning. You can change how they look,
              add labels to them, and use them to show relationships or filter information. To add connectors to your
              map, click a node, then click the arrow icon. The connector will point to whichever node you click next.
            </Typography>
          </GridItem>
        </GridContainer>
      </ContainerColored>
    </WrapperColored>
  );
};

export default MakeAMap;
